// import tTwo from "../../assets/images/zain.png"
// import tThree from "../../assets/images/umair.png"
// import tOne from "../../assets/images/raza.png"

import { zain, umair, raza } from "../../assets";

export const data = [
  {
    img: raza,
    three: "Raza Ali",
    para: "Co-Founder",
  },
  {
    img: zain,
    three: "Zain Amin",
    para: "Technical Team Lead",
  },
  {
    img: umair,
    three: "Jamshaid Javaid",
    para: "Project Coordinator",
  },
];
